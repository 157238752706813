import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import CategorieList from 'pages/categorie/List';
import CategorieView from 'pages/categorie/View';
import CategorieAdd from 'pages/categorie/Add';
import CategorieEdit from 'pages/categorie/Edit';
import OffertebandiList from 'pages/offertebandi/List';
import OffertebandiView from 'pages/offertebandi/View';
import OffertebandiAdd from 'pages/offertebandi/Add';
import OffertebandiEdit from 'pages/offertebandi/Edit';
import ProdottiList from 'pages/prodotti/List';
import ProdottiView from 'pages/prodotti/View';
import ProdottiAdd from 'pages/prodotti/Add';
import ProdottiEdit from 'pages/prodotti/Edit';
import UtentiList from 'pages/utenti/List';
import UtentiView from 'pages/utenti/View';
import UtentiAdd from 'pages/utenti/Add';
import UtentiEdit from 'pages/utenti/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* categorie pages routes */}
				<Route path="/categorie" element={<CategorieList />} />
				<Route path="/categorie/:fieldName/:fieldValue" element={<CategorieList />} />
				<Route path="/categorie/index/:fieldName/:fieldValue" element={<CategorieList />} />
				<Route path="/categorie/view/:pageid" element={<CategorieView />} />
				<Route path="/categorie/add" element={<CategorieAdd />} />
				<Route path="/categorie/edit/:pageid" element={<CategorieEdit />} />

				{/* offertebandi pages routes */}
				<Route path="/offertebandi" element={<OffertebandiList />} />
				<Route path="/offertebandi/:fieldName/:fieldValue" element={<OffertebandiList />} />
				<Route path="/offertebandi/index/:fieldName/:fieldValue" element={<OffertebandiList />} />
				<Route path="/offertebandi/view/:pageid" element={<OffertebandiView />} />
				<Route path="/offertebandi/add" element={<OffertebandiAdd />} />
				<Route path="/offertebandi/edit/:pageid" element={<OffertebandiEdit />} />

				{/* prodotti pages routes */}
				<Route path="/prodotti" element={<ProdottiList />} />
				<Route path="/prodotti/:fieldName/:fieldValue" element={<ProdottiList />} />
				<Route path="/prodotti/index/:fieldName/:fieldValue" element={<ProdottiList />} />
				<Route path="/prodotti/view/:pageid" element={<ProdottiView />} />
				<Route path="/prodotti/add" element={<ProdottiAdd />} />
				<Route path="/prodotti/edit/:pageid" element={<ProdottiEdit />} />

				{/* utenti pages routes */}
				<Route path="/utenti" element={<UtentiList />} />
				<Route path="/utenti/:fieldName/:fieldValue" element={<UtentiList />} />
				<Route path="/utenti/index/:fieldName/:fieldValue" element={<UtentiList />} />
				<Route path="/utenti/view/:pageid" element={<UtentiView />} />
				<Route path="/utenti/add" element={<UtentiAdd />} />
				<Route path="/utenti/edit/:pageid" element={<UtentiEdit />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
